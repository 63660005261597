import { Link, useRouteLoaderData, json } from "react-router-dom";
import * as React from "react";
import { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Graphs from "./Graphs";

function DeviceItem({ device }) {
  const [autoOffTime, setAutoOffTime] = React.useState(0);

  const handleAutoOffChange = (event) => {
    setAutoOffTime(event.target.value);
  };
  const [turnedOn, setTurnedOn] = useState(() => {
    console.log(device);
    if (device.settingsFromDevice == null) {
      return false;
    } else if (
      device.settingsFromDevice != null &&
      !device.settingsFromDevice.hasOwnProperty("valve")
    ) {
      return false;
    }
    return device.settingsFromDevice.valve;
  });
  const token = useRouteLoaderData("root");
  console.log(device);

  const handleSwitchWaterClick = async (turnedOn) => {
    console.log(device.settings);
    let settings = {};
    if (device.settings == null) {
      settings = {
        work_mode: autoOffTime == 0 ? "MANUAL" : "AUTO-OFF",
        turned_on: turnedOn,
        device: device.id,
      };
    } else if (
      device.settings != null &&
      !device.settings.hasOwnProperty("turned_on")
    ) {
      settings = {
        ...device.settings,
        turned_on: turnedOn,
      };
    } else {
      device.settings.turned_on = turnedOn;
      settings = device.settings;
    }
    delete settings._id;
    delete settings.createdAt;
    delete settings.updatedAt;
    console.log(settings);
    let response;
    if (autoOffTime == 0) {
      console.log("Sending Manual Request");
      response = await fetch("/api/settings/manual", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(settings),
      });
    } else {
      console.log("Sending Auto-Off Request");
      settings.auto_off_millis = autoOffTime;
      response = await fetch("/api/settings/auto-off", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(settings),
      });
    }

    console.log(response);
    if (!response.ok) {
      // return { isError: true, message: 'Could not fetch events.' };
      // throw new Response(JSON.stringify({ message: 'Could not fetch events.' }), {
      //   status: 500,
      // });
      throw json(
        { message: "Could not fetch events." },
        {
          status: 500,
        }
      );
    } else {
      const resData = await response.json();
      console.log(resData.data);
      setTurnedOn(turnedOn);
      return resData.data;
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box sx={{ width: "100%", maxWidth: 500 }}>
          <Typography gutterBottom variant="h5" component="div">
            {device.name}
          </Typography>
          <Typography gutterBottom variant="h5" component="div">
            {device.location}
          </Typography>
          {device.status === true ? (
            <Typography variant="body2" color="green">
              ONLINE
            </Typography>
          ) : (
            <Typography variant="body2" color="red">
              OFFLINE
            </Typography>
          )}
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="auto-off-label">Iskljuci za</InputLabel>
            <Select
              labelId="auto-off-label"
              id="auto-off-label"
              value={autoOffTime}
              onChange={handleAutoOffChange}
              label="AutoOffTime"
            >
              <MenuItem value={0}>Rucno</MenuItem>
              <MenuItem value={300000}>5 min</MenuItem>
              <MenuItem value={600000}>10 min</MenuItem>
              <MenuItem value={1800000}>20 min</MenuItem>
              <MenuItem value={2400000}>30 min</MenuItem>
              <MenuItem value={3000000}>40 min</MenuItem>
              <MenuItem value={3600000}>50 min</MenuItem>
              <MenuItem value={4200000}>60 min</MenuItem>
            </Select>
          </FormControl>
          {turnedOn === false ? (
            <>
              <Typography variant="body2" color="red">
                Voda iskljucena
              </Typography>
              <Button
                variant="contained"
                onClick={() => handleSwitchWaterClick(true)}
              >
                Ukljuci
              </Button>
            </>
          ) : (
            <>
              <Typography variant="body2" color="green">
                Voda ukljucena
              </Typography>
              <Button
                variant="contained"
                onClick={() => handleSwitchWaterClick(false)}
              >
                Iskljuci
              </Button>
            </>
          )}
          {turnedOn === true && device.settingsFromDevice.endTimeIn > 0 ? (
            <>
              <Typography variant="body2" color="green">
                Iskljucuje se za:{" "}
                {(device.settingsFromDevice.endTimeIn / 1000 / 60).toFixed(2)}{" "}
                min
              </Typography>
            </>
          ) : (
            <></>
          )}
        </Box>
        <Graphs deviceId={device.id}></Graphs>
      </Container>
    </React.Fragment>
  );
}

export default DeviceItem;

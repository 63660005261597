import { Link, useRouteLoaderData, json } from "react-router-dom";
import * as React from "react";
import { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TemperatureGraph from "./TemperatureGraph";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import HumidityGraph from "./HumidityGraph";
import RainfallGraph from "./RainfallGraph";
import FlowGraph from "./FlowGraph";

function Graphs(props) {
  const [graphsOn, setGraphsOn] = useState(false);
  const [graphsData, setGraphsData] = useState([
    { createdAt: new Date(), temperature: 0, humidity: 0 },
  ]);
  const [graphsDate, setGraphsDate] = useState(dayjs());

  const handleSwitchGraphsClick = async (graphsOn) => {
    setGraphsOn(graphsOn);
    if (graphsOn) {
      var reqBody = {
        device: props.deviceId,
        year: graphsDate.year(),
        month: graphsDate.month() + 1,
        day: graphsDate.date(),
      };
      const response = await fetch("/api/readings/all", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(reqBody),
      });
      console.log(response.ok);
      if (!response.ok) {
        // return { isError: true, message: 'Could not fetch events.' };
        // throw new Response(JSON.stringify({ message: 'Could not fetch events.' }), {
        //   status: 500,
        // });
        throw json(
          { message: "Could not fetch graphs data." },
          {
            status: 500,
          }
        );
      } else {
        const resData = await response.json();
        let totalRainfall = 0;
        let totalFlow = 0;
        resData.data.forEach(function (arrayItem) {
          arrayItem.createdAt = new Date(arrayItem.createdAt);
          if (arrayItem.hasOwnProperty("rainfall")) {
            totalRainfall = totalRainfall + arrayItem.rainfall;
            arrayItem.rainfall = totalRainfall;
          }
          if (arrayItem.hasOwnProperty("flow")) {
            totalFlow = totalFlow + arrayItem.flow / 1000;
            arrayItem.flow = totalFlow;
          }
        });
        setGraphsData(resData.data);
      }
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        {graphsOn === false ? (
          <>
            <Typography variant="body2" color="red">
              Pokazi grafove
            </Typography>
            <Button
              variant="contained"
              onClick={() => handleSwitchGraphsClick(true)}
              disabled={graphsDate == undefined ? true : false}
            >
              Pokazi
            </Button>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Datum"
                  value={graphsDate}
                  onChange={(newValue) => setGraphsDate(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </>
        ) : (
          <>
            <Typography variant="body2" color="green">
              Sakrij grafove
            </Typography>
            <Button
              variant="contained"
              onClick={() => handleSwitchGraphsClick(false)}
            >
              Sakrij
            </Button>
          </>
        )}
        {graphsOn === true ? (
          <>
            <TemperatureGraph
              graphsData={graphsData}
              graphsDate={graphsDate}
            ></TemperatureGraph>
            <HumidityGraph
              graphsData={graphsData}
              graphsDate={graphsDate}
            ></HumidityGraph>
            <RainfallGraph
              graphsData={graphsData}
              graphsDate={graphsDate}
            ></RainfallGraph>
            <FlowGraph
              graphsData={graphsData}
              graphsDate={graphsDate}
            ></FlowGraph>
          </>
        ) : (
          <></>
        )}
      </Container>
    </React.Fragment>
  );
}

export default Graphs;

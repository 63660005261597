import { Link, useRouteLoaderData, json } from "react-router-dom";
import * as React from "react";
import { useState } from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { LineChart } from '@mui/x-charts/LineChart';

const customize = {
  height: 300
};


function FlowGraph(props) {
  const token = useRouteLoaderData("root");

  return (
    <Container maxWidth="sm">
      Potrosnja vode (u L)
      <LineChart
        xAxis={[
          {
            dataKey: "createdAt",
            scaleType: "time",
          },
        ]}
        series={[
          {
            dataKey: "flow",
          },
        ]}
        dataset={props.graphsData}
        {...customize}
      />
    </Container>
  );
}

export default FlowGraph;

import { Suspense } from "react";
import {
  useRouteLoaderData,
  json,
  redirect,
  defer,
  Await,
} from "react-router-dom";
import DeviceItem from "../components/DeviceItem";

function DeviceDetailPage() {
  const { device } = useRouteLoaderData("device-detail");
  return (
    <Suspense fallback={<p style={{ textAlign: "center" }}>Loading...</p>}>
      <Await resolve={device}>
        {(loadedDevice) => <DeviceItem device={loadedDevice} />}
      </Await>
    </Suspense>
  );
}

export default DeviceDetailPage;

async function loadDevice(id) {
  console.log("Tu sam Detail");
  const response = await fetch("/api/devices/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
  const device = {
    device: id,
  };

  console.log("Tu sam Detail");
  const responseSettings = await fetch("/api/settings/last", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    body: JSON.stringify(device),
  });

  console.log("Tu sam Detail");
  const responseSettingsFromDevice = await fetch("/api/settings/from-device", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    body: JSON.stringify(device),
  });

  console.log("Tu sam Detail");
  console.log(response);
  if (!response.ok || !responseSettings.ok) {
    // return { isError: true, message: 'Could not fetch events.' };
    // throw new Response(JSON.stringify({ message: 'Could not fetch events.' }), {
    //   status: 500,
    // });
    throw json(
      { message: "Could not fetch device." },
      {
        status: 500,
      }
    );
  } else {
    const resData = await response.json();
    const resSettings = await responseSettings.json();
    const resSettingsFromDevice = await responseSettingsFromDevice.json();

    console.log("Tu sam DetailEl");
    return {
      ...resData.data,
      settings: resSettings.data,
      settingsFromDevice: resSettingsFromDevice.data,
    };
  }
}

export async function loader({ request, params }) {
  const id = params.deviceId;

  return defer({
    device: await loadDevice(id),
  });
}

import { Link, useRouteLoaderData, json } from "react-router-dom";
import * as React from "react";
import { useState } from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { LineChart } from '@mui/x-charts/LineChart';

const worldElectricityProduction = [
  {
    time: "2024-07-25T12:51:13.006Z",
    temperature: 24.5,
  },
  {
    time: "2024-07-25T13:51:13.006Z",
    temperature: 24.6,
  },
  {
    time: "2024-07-25T14:51:13.006Z",
    temperature: 25.5,
  },
  {
    time: "2024-07-25T15:51:13.006Z",
    temperature: 26.6,
  },
];

const customize = {
  height: 300
};


function TemperatureGraph(props) {
  const token = useRouteLoaderData("root");

  return (
    <Container maxWidth="sm">
      Temperatura zraka
            <LineChart
              xAxis={[
                {
                  dataKey: "createdAt",
                  scaleType: 'time',
                },
              ]}
              series={[
                {
                  dataKey: "temperature",
                  //valueFormatter: (value) => value.toString(),
                },
              ]}
            dataset={props.graphsData}
            {...customize}
            />
      </Container>
  );
}

export default TemperatureGraph;
